<template>
    <div class="UserAnaly_A">
        <div class="rana_con">
            <div class="rana_con_title">
                你觉得这些人群中谁是你的目标客户？（在下列标签中选择一个）
            </div>
            <!-- 内容 -->
            <div class="rana_con_content">
                <div class="arrow_box">
                    <div class="arrow_box_left">
                        <img src="./../../../assets/img/arrow_left.png" alt="" @click="prevBox()" v-show="this.currentpage>1">
                    </div>
                    <ul class="arrow_box_cente">
                        <li v-for="(item,index) in caseList" :key="index" :class="item.val==1?'li_up':'li_down'" @click="caseBox(item,index)">
                            <div class="arr_item_img">
                                <img v-if="item.val==1" src="./../../../assets/img/icon_white.png" alt="">
                                <img v-else src="./../../../assets/img/icon_blue.png" alt="">
                            </div>
                            <div class="tiem_one" style="margin-top:10px">
                                <span class="_one_span">目标客户</span><span>：{{item.name}}</span>
                            </div>
                            <div class="tiem_one">
                                <span class="_one_span">主语</span><span>：{{item.role}}</span>
                            </div>
                            <div class="tiem_one_gm">
                                <span class="_one_span">购买动机</span><span :title="item.gmdj">：{{item.gmdj}}</span>
                            </div>
                            <div class="tiem_one_gm">
                                <span class="_one_span">购买行为</span><span :title="item.gmxw">：{{item.gmxw}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="arrow_box_left">
                        <img src="./../../../assets/img/arrow_right.png" alt="" @click="nexBox()" v-show="totalnum>5">
                    </div>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="butt_div">
                <div class="button_one" v-preventReClick @click="prevstep()">
                    <span>取消</span>
                </div>
                <div class="button_two" v-preventReClick @click="nextBotn()">
                    <span>确定</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            istrue:0,
            caseList:[],
            currentpage:1,
            totalnum:0
        };
    },
    methods: {
        getapp(){
            this.axios.projectS2P1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.array = res.data.data.values
                    this.totalnum = res.data.data.values.length
                    const newarray = []
                    const allpage = Math.ceil(res.data.data.values.length / 5)
                    for (var i = 0; i < allpage; i++) {
                        newarray.push(res.data.data.values.slice(i * 5, i * 5 + 5))
                    }
                    this.newarrays = newarray
                    this.caseList = this.newarrays[this.currentpage - 1]
                    if(this.caseList==undefined){
                        this.caseList = this.newarrays[this.currentpage - 2];
                    }
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        caseBox(item,val){
            this.newarrays.forEach((el,index)=>{
                el.forEach(els=>{
                    if(els.id == item.id){
                        els.val = 1
                    }else{
                        els.val = 0
                    }
                })
              })
        },
        // 上一页
        prevBox(){
            if(this.currentpage>1){
                this.currentpage = this.currentpage - 1
                this.caseList = this.newarrays[this.currentpage - 1]
            }else{
                this.currentpage = 1
            }
        },
        //下一页
        nexBox(){
            if(this.currentpage * 5 < this.totalnum){
                this.currentpage = this.currentpage + 1
                this.caseList = this.newarrays[this.currentpage - 1]
            }else{
                
            }
        },
        prevstep(){
            this.$router.push({name:'StudentPage'})
        },
        nextBotn(){
            let array = this.newarrays
            var arr = []
            for(var i in array){
               arr = arr.concat(array[i])
            }
            // arr.map((item,index)=>{
            //     if(this.currentpage>1){
            //         if((this.currentpage - 1) * 5 + this.istrue == index){
            //             item.val = 1
            //         }else{
            //             item.val = 0
            //         }
            //     }else{
            //         if(this.istrue == index){
            //             item.val = 1
            //         }else{
            //             item.val = 0
            //         }
            //     }   
            // })
            this.axios.Sets2({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
                node:'p1',
                value:arr,
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push({name:'CustorAnaly_B'})
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
};
</script>
<style scoped>
@import './../../../assets/css/student/A/UserAnaly_A.css';
</style>